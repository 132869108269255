import React, { useCallback, useReducer } from 'react';
import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { confirm } from "react-confirm-box";
import { useEffect, useState } from "react";
import { CustomerData, UnitLoadList, SDList, BDList, SupplierData, OEMData, ComponentFamily } from '../../apidata/fetchapi';
import { useNavigate,NavLink, Link } from 'react-router-dom';
import { ulmsUrl } from '../../Api';
import { password, username } from '../../Api';
import Select from 'react-select';
import Loader from '../../loader/Loader';
import DateFormat from '../dateformate/DateFormat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SolutionGallery = () => {

    const { supplierDropdown }  = SupplierData();
    const { oemDropdown }       = OEMData();
    const { comFamOptions }     = ComponentFamily();
    const { bdListData }        = BDList();
    const { sdListData }        = SDList();
    const { outerULData }       = UnitLoadList();

    let base64          = require('base-64');
    const navigate      = useNavigate();
    const usr_token     = localStorage.getItem('authToken');
    const usr_name      = localStorage.getItem('name');
    const usr_id        = localStorage.getItem('usrId');
    const isUserLogin   = localStorage.getItem('isAuthenticatedUser');

    const bRef = useRef(null);

    const initialLeadData = {
        "project_type": "",
        "products": "",
        "service_type": "",
        "supplier_name": "",
        "new_supplier_name": "",
        "oem_name":"",
        "new_oem_name":"",
        "prop_outer_packaging_val": "",
        "component_name": "",
        "component_family": "",
        "project_desc": "",
        "solution_dimension_collapsed": "",
        "solution_dimension": "",
        "weight_carrying_capacity": "",
        "stacking_dynamic": "",
        "stacking_static": "",
        "solution_description": "",
        "bd_person_name": "",
        "sd_person_name": "",
        "img_data": "",
        "lead_req_id": usr_id,
        "lead_req_name": usr_name,
        "usr_token": usr_token,
    }
  
    const [leadCreate, setLeadcreate]             = useState(initialLeadData);
    const [selected, setSelected]                 = useState("");
    const [lsSelected, setLSSelected]             = useState('');
    const [productselected, setProductSelected]   = useState("");
    const [productOption, setProductOptions]      = useState([""]);
    const [serServiceOption, setServiceOptions]   = useState([""]);
    const [lobSelected, setLobSelected]           = useState("");
    const [customerData, setCustomerData]         = useState([""]);
    const [otherselected, setOtherSelected]       = useState([""]);
    const [oemOtherSelected, setOemOtherSelected] = useState([""]);
    const [trialFile, setTrialFile]               = useState([]);
    const [loading, setLoading]                   = useState(false);
    const [solFile1, setSolFile]                  = useState([]);
    const [bomFile, setBomFile]                   = useState([]);
    const [refFile, setRefFile]                   = useState([]);


    const handleFormInput = (e) => {

        if (e && e.target) {
            const { name, value } = e.target;

            setLeadcreate(oldValue => ({
                ...oldValue,
                [name]: value,
                "multi_ls": lsSelected,
            }))
        }
       
    };

    const getProductData = async (e) => {
        
        if (e && e.target) {
            const { name, value } = e.target;
            setLeadcreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }

        let project_type = e.target.value;
    
        if(project_type != ""){

            fetch(ulmsUrl.concat('/mst/product_list'), {
                method: 'POST',
                body: JSON.stringify({ usr_token, project_type }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                let result = response.data;
                setProductOptions(response.data)
            });
        }
    }

    const getServiceData = async (e) => {

        if (e && e.target) {
            const { name, value } = e.target;
            setLeadcreate(oldValue => ({
                ...oldValue,
                [name]: value,
            }))
        }

        let project_type = lobSelected;
        let products  = e.target.value;

        if(products != ""){
        
            fetch(ulmsUrl.concat('/mst/service_list'), {
                method: 'POST',
                body: JSON.stringify({ usr_token, project_type, products }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) =>
                response.json()
            )
            .then((response) => {
                
                let result = response.data;
                setServiceOptions(response.data)
                // console.log('LS',response.include_ls)
                // setLSServiceOptions(response.include_ls)
            });
        }
    }

    const handleLSInput = (e) => {
        const { name, value } = e.target;
        
        if (e && (e.target.checked)) {
            setLSSelected(oldval => ([...oldval, value]))

        }else {
            setLSSelected(lsSelected =>
                lsSelected.filter(item => item !== value)
            );
        }
    }

   

    useEffect(() => {

        if(lobSelected != ""){
            getProductData();
        }
        if(productselected != ""){
            getServiceData();
        }

    }, []);
    
    const handleSuppNameInput = (data) => { 
        console.log(data);
        // setCustomerName(data.label);
        // setCustomerData([""]);
        let supp_id = data.value;
        setOtherSelected(data.value);
        setLeadcreate(oldValue => ({
            ...oldValue,
            "supplier_name": data,
        }))  

        // if(supp_id != ""){
        //     console.log(JSON.stringify({ supp_id }))

        //     fetch(ulmsUrl.concat('/holibook/supplier_info'), {
        //         method: 'POST',
        //         body: JSON.stringify({ supp_id }),
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Basic ' + base64.encode(username + ":" + password)
        //         }
        //     })
        //     .then((response) =>
        //         response.json()
        //     )
        //     .then((response) => {
        //         let result = response.data;
        //         // setCustomerData(response.data)
        //         setLeadcreate(oldValue => ({
        //             ...oldValue,
        //             "entity_name" : result.entity_name,
        //             "pincode"     : result.pin_code,
        //             "supplier_loc": result.address,
        //             "cust_industry_verticle": result.cust_industry_verticle,
        //         })) 
        //     });
        // } 
    };

    const handleOemNameInput = (data) => {
        let oem_id = data.value;
        // setOemName(data.label);
        // setOemData([""]);
        setOemOtherSelected(data.value);
        setLeadcreate(oldValue => ({
            ...oldValue,
            "oem_name": data,
        })) 

        // if(oem_id != ""){
        //     console.log(JSON.stringify({ oem_id }))

        //     fetch(ulmsUrl.concat('/holibook/oem_info'), {
        //         method: 'POST',
        //         body: JSON.stringify({ oem_id }),
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': 'Basic ' + base64.encode(username + ":" + password)
        //         }
        //     })
        //     .then((response) =>
        //         response.json()
        //     )
        //     .then((response) => {
        //         let result = response.data;
        //         // setOemData(response.data)
        //         setLeadcreate(oldValue => ({
        //             ...oldValue,
        //             "oem_entity_name" : result.entity_name,
        //             "oem_pincode"     : result.pin_code,
        //             "oem_loc": result.address,
        //             "oem_industry_verticle": result.oem_industry_verticle,
        //         })) 
        //     });
        // } 
    }

    const handleComFamInput = (value) => { 

        setLeadcreate(oldValue => ({
            ...oldValue,
            "component_family": value,
        }))  
    };
   
    // const handleULCategoryInput = (value) => { 

    //     setLeadcreate(oldValue => ({
    //         ...oldValue,
    //         "ul_category": value,
    //     })) 

    // };

    const handlePropOutPackInput = (value) => { 
        setLeadcreate(oldValue => ({
            ...oldValue,
            "prop_outer_packaging_val": value,
        })) 

    };

    const handleBDNameInput = (value) => { 
        setLeadcreate(oldValue => ({
            ...oldValue,
            "bd_person_name": value,
        })) 

    };

    const handleSDNameInput = (value) => { 
        setLeadcreate(oldValue => ({
            ...oldValue,
            "sd_person_name": value,
        })) 

    };
    const handleTrialFileChange = (event) => {
        const selectedFile = event.target.files;
        setTrialFile(selectedFile)
    };

    const handleSolFileChange = (event) => {
        const selectedFile = event.target.files;
        setSolFile(selectedFile);
    };
    
    const handleBomFileChange = (event) => {
        const selectedFile = event.target.files;
        setBomFile(selectedFile);
    };

    const handleRefFileChange = (event) => {
        const selectedFile = event.target.files;
        setRefFile(selectedFile);
    };
  

    const handleFormSubmit = async (e) =>{
        setLoading(true);

        e.preventDefault();

        const formData = new FormData();
        for(let i = 0; i < trialFile.length; i++){
            formData.append(`opportunity_photo_trial`, trialFile[i]);
        }
        for(let i = 0; i < solFile1.length; i++){
            formData.append(`opportunity_photo_sol`, solFile1[i]);
        }
        for(let i = 0; i < bomFile.length; i++){
            formData.append(`opportunity_photo_tech`, bomFile[i]);
        }
        for(let i = 0; i < refFile.length; i++){
            formData.append(`opportunity_photo_ref`, refFile[i]);
        }
        
        formData.append('data', JSON.stringify(leadCreate));
        console.log(JSON.stringify(leadCreate));
        console.log(formData);  

        fetch(ulmsUrl.concat('/backend/form'), {
            method: 'POST',
            body: formData,
        })
        .then((result) =>
            result.json()
        )
        .then((result) => {
            console.log(result)
            if (result.status == true) {
                setLeadcreate(initialLeadData)
                setLoading(false)   
                sessionStorage.setItem("createmessage", result.message);
                navigate("/backend_leads", { replace: true })
                // navigate("/thank_you", { replace: true })

            } else if (result.status == false) {
                setLoading(false)
                setLeadcreate(leadCreate)
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: 'success1',
                });
               
            }
        })
        .catch((form_err) => {
            //console.log(form_err)
        })

    }

    return (
        <>
            <ToastContainer />
            {loading && (
            <>
                <Loader/>
            </>
            )}
            <div className="row">
                <div className="order-2 order-lg-1 col-lg-12 bd-content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">UPLOAD SOLUTION</h3>
                        </div>
                        <div className="card-body">
                            <form className="row g-3" onSubmit={handleFormSubmit} encType="multipart/form-data">
                                <div className="col-md-12">  
                                    <Form.Label style={{marginRight: '15px'}} >Select LOB : <span className="required-mark">*</span></Form.Label>
                                    <Form.Check
                                        inline
                                        label="AUTO"
                                        name="project_type"
                                        value="AUTO"
                                        type= "radio"
                                        onClick={()=>setLobSelected("AUTO")}
                                        onChange={getProductData}
                                        id={`inline-radio-2`}
                                    />
                                    <Form.Check
                                        inline
                                        label="RETAIL"
                                        name="project_type"
                                        value="RETAIL"
                                        type= "radio"
                                        onClick={()=>setLobSelected("RETAIL")}
                                        onChange={getProductData}
                                        id={`inline-radio-1`}
                                    />
                                </div>
                                { (lobSelected === "AUTO" || lobSelected === "RETAIL") && <div className="col-md-6">  
                                    <Form.Label style={{marginRight: '15px'}} >Select Products :<span className="required-mark">*</span></Form.Label>
                                    {productOption.map(value => (
                                        <Form.Check
                                            inline
                                            key={value.id}
                                            label={value.full_name}
                                            name="products"
                                            value={value.name}
                                            type= "radio"
                                            onClick={()=>setProductSelected(value.name)}
                                            onChange={getServiceData}
                                        />
                                    ))}
                                </div> }
                                { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && productselected != "" && productselected != "LS") && <div className="col-md-12">  
                                <Form.Label style={{marginRight: '15px'}} >Select Services :<span className="required-mark">*</span></Form.Label>
                                    {serServiceOption.map(value => (
                                        <Form.Check
                                            inline
                                            key={value.id}
                                            label={value.full_name}
                                            name="service_type"
                                            style={{marginTop: '5px'}}
                                            value={value.name}
                                            type= "radio"
                                            onClick={()=>setSelected(value.name)}
                                            onChange={handleFormInput}
                                        />
                                    ))}
                                </div> } 
                                { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && productselected != "" && productselected == "LS" ) && <div className="col-md-12">  
                                <Form.Label style={{marginRight: '15px'}} >Select Services :<span className="required-mark">*</span></Form.Label>
                                    {serServiceOption.map(value => (
                                        <Form.Check
                                            inline
                                            key={value.id}
                                            label={value.full_name}
                                            name="service_type"
                                            value={value.name}
                                            type= "checkbox"
                                            onClick={()=>setSelected(value.name)}
                                            onChange={handleLSInput}
                                        />
                                    ))}
                                </div> } 

                                { ((lobSelected === "AUTO" || lobSelected === "RETAIL") && (selected != "" || lsSelected != "")) && 
                                <div className='row' style={{marginTop: '5px'}}>
                                    {selected == 'RP' && 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Supplier Name :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="supplier_name"
                                            options={supplierDropdown}
                                            onChange={handleSuppNameInput}
                                        />
                                    </div> } 
                                    {selected == 'NRP' && 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Customer Name :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="supplier_name"
                                            options={supplierDropdown}
                                            onChange={handleSuppNameInput}
                                        />
                                    </div>}
                                    { otherselected == 666666 ?
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Supplier Name :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="new_supplier_name"
                                            value={leadCreate.new_supplier_name}
                                            onChange={handleFormInput}
                                            id="new_supplier_name"
                                            className="form-control" placeholder="Enter customer name" />      
                                    </div> : '' }
                                    { selected == 'RP' && productselected !== "LS" &&
                                      <>
                                        <div className="col-md-3" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label">OEM Name :<span className="required-mark">*</span> </label>
                                            <Select
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                name="oem_name"
                                                options={oemDropdown}
                                                onChange={handleOemNameInput}
                                            />
                                        </div>
                                        { oemOtherSelected == 666666 ?
                                        <div className="col-md-3" style={{marginTop: '5px'}}>
                                            <label htmlFor="inputState" className="form-label"> Oem Name :<span className="required-mark">*</span> </label>
                                            <Form.Control
                                                type="text"
                                                name="new_oem_name"
                                                value={leadCreate.new_oem_name}
                                                onChange={handleFormInput}
                                                id="new_oem_name"
                                                className="form-control" placeholder="Enter oem name" />      
                                        </div> : '' }
                                      </>
                                    }
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Select Proposed Outer Packaging :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="prop_outer_packaging_val"
                                            options={outerULData}
                                            onChange={handlePropOutPackInput}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Component Name :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="component_name"
                                            value={leadCreate.component_name}
                                            onChange={handleFormInput}
                                            id="component_name"
                                            className="form-control" placeholder="Enter component name" />      
                                    </div> 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Select Component Family :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="component_family"
                                            options={comFamOptions}
                                            onChange={handleComFamInput}
                                        />
                                    </div>
                                    {/* <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Select UL Category :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="ul_category"
                                            options={comFamOptions}
                                            onChange={handleULCategoryInput}
                                        />
                                    </div> */}
                                    <div className="col-md-3"  style={{marginTop: '5px'}}>
                                        <Form.Label>Project Description : <span className="required-mark">*</span></Form.Label>
                                        <Form.Control 
                                            className="form-control"
                                            as="textarea"
                                            rows={1}
                                            placeholder="Enter Description"
                                            name="project_desc"
                                            value={leadCreate.project_desc}
                                            onChange={handleFormInput}
                                            autoComplete='off'
                                        />   
                                    </div>
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Solution Dimension Collapsed :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="solution_dimension_collapsed"
                                            value={leadCreate.solution_dimension_collapsed}
                                            onChange={handleFormInput}
                                            id="solution_dimension_collapsed"
                                            className="form-control" placeholder="Enter solution dimension collapsed" />      
                                    </div> 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Solution Dimension :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="solution_dimension"
                                            value={leadCreate.solution_dimension}
                                            onChange={handleFormInput}
                                            id="solution_dimension"
                                            className="form-control" placeholder="Enter solution dimension" />      
                                    </div> 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Weight Carrying Capacity :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="weight_carrying_capacity"
                                            value={leadCreate.weight_carrying_capacity}
                                            onChange={handleFormInput}
                                            id="weight_carrying_capacity"
                                            className="form-control" placeholder="Enter weight carrying capacity" />      
                                    </div> 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Stacking Dynamic :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="stacking_dynamic"
                                            value={leadCreate.stacking_dynamic}
                                            onChange={handleFormInput}
                                            id="stacking_dynamic"
                                            className="form-control" placeholder="Enter stacking dynamic" />      
                                    </div> 
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Stacking Static :<span className="required-mark">*</span> </label>
                                        <Form.Control
                                            type="text"
                                            name="stacking_static"
                                            value={leadCreate.stacking_static}
                                            onChange={handleFormInput}
                                            id="stacking_static"
                                            className="form-control" placeholder="Enter stacking static" />      
                                    </div> 
                                    <div className="col-md-3"  style={{marginTop: '5px'}}>
                                        <Form.Label>Solution Description : <span className="required-mark">*</span></Form.Label>
                                        <Form.Control 
                                            className="form-control"
                                            as="textarea"
                                            rows={1}
                                            placeholder="Enter Description"
                                            name="solution_description"
                                            value={leadCreate.solution_description}
                                            onChange={handleFormInput}
                                            autoComplete='off'
                                        />   
                                    </div>
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Select BD Name :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="bd_person_name"
                                            options={bdListData}
                                            onChange={handleBDNameInput}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{marginTop: '5px'}}>
                                        <label htmlFor="inputState" className="form-label">Select SD Name :<span className="required-mark">*</span> </label>
                                        <Select
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            name="sd_person_name"
                                            options={sdListData}
                                            onChange={handleSDNameInput}
                                        />
                                    </div>
                                    <div className="col-md-12" style={{marginTop: '5px'}}>
                                        <div className='row'>
                                            <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '10px'}}>
                                                <Form.Label style={{fontWeight:'bold'}}>Upload Solution File :</Form.Label>
                                                <Form.Control multiple type="file"
                                                ref={bRef}    
                                                name="opportunity_photo_sol"
                                                onChange={handleSolFileChange}
                                                />
                                            </div>
                                            <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '10px'}}>
                                                <Form.Label style={{fontWeight:'bold'}}>Upload Tech File :</Form.Label>
                                                <Form.Control type="file"
                                                multiple
                                                ref={bRef}    
                                                name="opportunity_photo_tech"
                                                onChange={handleBomFileChange}
                                                />
                                            </div>
                                            <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '10px'}}>
                                                <Form.Label style={{fontWeight:'bold'}}>Upload Related Ref File :</Form.Label>
                                                <Form.Control type="file"
                                                multiple
                                                ref={bRef}    
                                                name="opportunity_photo_ref"
                                                onChange={handleRefFileChange}
                                                />
                                            </div>
                                            <div className="col-md-3" controlid="formFileMultiple" style={{marginTop: '10px'}}>
                                                <Form.Label style={{fontWeight:'bold'}}>Upload Trial File:</Form.Label>
                                                <Form.Control multiple type="file"  
                                                ref={bRef}    
                                                name="opportunity_photo_trial"
                                                onChange={handleTrialFileChange}  />
                                            </div>
                                        </div>
                                    </div>
                                    
                                     <div className="col-md-12" style={{marginTop: '15px'}}>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                     </div> 

                                </div> }

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SolutionGallery